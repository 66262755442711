import React, { useState, useEffect } from "react";
import { fetchWithToken } from "helpers/fetch";
import { endPoints } from "const/endPoints";
import { formatDate } from "utils/formatDate";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Container,
  Pagination,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Dialog } from "primereact/dialog";
import Stack from "@mui/material/Stack";
import { formatYupErrors } from "helpers/formatYupErrors";
import { useSelector } from "react-redux";
import { startCreatePartialPayment } from "actions/payments";
import Toast from "components/common/Popup/Toast";
import Transfer from "components/layout/PaymentGateway/TypePaids/Transfer";
import PaidMobile from "components/layout/PaymentGateway/TypePaids/PaidMobile";
import Cash from "components/layout/PaymentGateway/TypePaids/Cash";
import VerifiedIcon from "@mui/icons-material/Verified";
import ClipLoader from "react-spinners/ClipLoader";
import AddIcon from "@mui/icons-material/Add";
import { Form } from "components/layout/PaymentGateway/styles";

const quotas = [200, 100, 100, 100];
const MyPayments = () => {
  const user = useSelector((state) => state.auth.user);
  const [sicad, setSicad] = useState();
  const [coupon, setCoupon] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [verifiedCoupon, setVerifiedCoupon] = useState();
  const [paymentAccount, setPaymentAccount] = useState({});
  const [isPartial, setIsPartial] = useState(false);
  const [payableAmount, setPayableAmount] = useState();
  const [error, setError] = useState(null);
  const [payments, setPayments] = useState([]);
  const [actualPayment, setActualPayment] = useState();
  const [values, setValues] = useState({
    paymentMethod: "",
    amount: payableAmount,
    paymentReference: "",
    courseId: (actualPayment && actualPayment.id) || "",
    isPartial,
    numberOfQuotas: 1,
    paymentId: "",
  });

  const getPaymentAccount = async () => {
    const res = await fetchWithToken(endPoints.payment_get_account);
    const body = await res.json();
    if (res.status === 200) {
      setPaymentAccount(body);
    }
  };

  useEffect(() => {
    getPaymentAccount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error === null) {
      setLoading(true);
      if (verifiedCoupon) {
        values.coupon = verifiedCoupon.id;
        values.amount = isPartial ? 200 : payableAmount;
      }
      if (isPartial) {
        values.isPartial = isPartial;
        values.amount = payableAmount;
        values.paymentId = actualPayment.id;
      }
      const body = await startCreatePartialPayment(values);
      if (!body.statusCode) {
        setLoading(false);
        Toast("success", "Datos del pago enviados con exito");
        if (payableAmount === 0) {
          window.location.href = "/profile/courses";
        }
        setVisible(false);
      } else {
        Toast("error", body.message);
      }
    }
  };

  const handleCouponVerification = async () => {
    setLoading(true);
    const checkCoupon = await fetchWithToken(
      endPoints.verify_coupon,
      { name: coupon, course: actualPayment.id },
      "POST"
    );
    const data = await checkCoupon.json();
    if (data.statusCode === 200) {
      setVerifiedCoupon(data.coupon);
      const amount =
        payableAmount - (payableAmount * data.coupon.discount) / 100;
      setPayableAmount(amount);
      if (amount === 0) {
        setMessage(true);
      }
      Toast("success", data.message);
    } else {
      Toast("error", data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getSicad = async () => {
      const data = await fetch("https://ve.dolarapi.com/v1/dolares/oficial");
      const resp = await data.json();
      setSicad(resp.promedio.toFixed(2));
    };
    getSicad();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      const resp = await fetchWithToken(endPoints.get_all_payment_users);
      const body = await resp.json();
      body.map(
        (payment) =>
          (payment.createdAt = new Date(payment.createdAt).toLocaleString())
      );
      setPayments(body);
    };
    fetchPayments();
  }, []);

  const [page, setPage] = useState(1); //pagina actual
  const [visible, setVisible] = useState(false);
  const n = 6; //Numero de elementos por paginas
  const totalPages = Math.ceil(payments.length / n) + 1; //total de paginas
  //ordenar payments desde el ultimo elemento al primero
  const sortedPayments = payments.slice().reverse();
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  //*Funcion para listar los usuarios por pagina
  const setitemsInPage = () => {
    let pagination = {};
    let j = 0;
    for (let i = 1; i <= totalPages; i++) {
      pagination[i] = sortedPayments.slice(j * n, j * n + n);
      j++;
    }

    return pagination;
  };
  const itemList = setitemsInPage();

  function calculatePayableAmount(selectedQuotaIndex, actualPayment) {
    const paidQuotas = Number(actualPayment.numberOfQuotas); // Número de cuotas ya pagadas

    // Si seleccionas una cuota ya pagada, el monto a pagar es 0
    if (selectedQuotaIndex - 1 < paidQuotas) {
      return 0;
    }

    // Solo sumamos cuotas pendientes desde la última pagada
    const remainingQuotas = quotas.slice(paidQuotas);

    // Calculamos la suma hasta la cuota seleccionada
    const payableAmount = remainingQuotas
      .slice(0, selectedQuotaIndex - 1 - paidQuotas + 1) // Desde la cuota actual hasta la seleccionada
      .reduce((sum, quota) => sum + quota, 0);

    // Garantizamos que no exceda el máximo permitido
    return Math.min(payableAmount, actualPayment.totalAmount);
  }

  return (
    <>
      {sortedPayments && sortedPayments.length !== 0 && (
        <Container
          sx={{
            padding: "2rem 0",
            height: "70%",
          }}
        >
          {/* //* TITULO */}
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 0 2rem 0",
              fontSize: "1.5rem",
            }}
          >
            Historial de pagos
          </Typography>

          {/* //* header del historial de compras */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "1.5rem 0",
              boxShadow: "0px 0px 5px -1px   #999898",
            }}
          >
            <Typography
              sx={{
                width: "16%",
                flexShrink: 0,
                borderRight: "solid 1px #999898",
                textAlign: "center",
                padding: "0 0.5rem",
                fontWeight: "600",
              }}
            >
              N.Ref
            </Typography>
            <Typography
              sx={{
                width: "16%",
                flexShrink: 0,
                borderRight: "solid 1px #999898",
                textAlign: "center",
                padding: "0 0.5rem",
                fontWeight: "600",
              }}
            >
              Producto
            </Typography>
            <Typography
              sx={{
                width: "16%",
                flexShrink: 0,
                borderRight: "solid 1px #999898",
                textAlign: "center",
                padding: "0 0.5rem",
                fontWeight: "600",
              }}
            >
              Fecha
            </Typography>
            <Typography
              sx={{
                width: "16%",
                flexShrink: 0,
                borderRight: "solid 1px #999898",
                textAlign: "center",
                padding: "0 0.5rem",
                fontWeight: "600",
              }}
            >
              Monto
            </Typography>
            <Typography
              sx={{
                width: "16%",
                flexShrink: 0,
                borderRight: "solid 1px #999898",
                textAlign: "center",
                padding: "0 0.5rem",
                fontWeight: "600",
              }}
            >
              Estado
            </Typography>
          </Box>

          {/* //* Contenido del historial de compras */}
          <Box
            sx={{
              margin: "1.5rem 0rem",
              height: "70%",
            }}
          >
            {itemList[page].map((item, i) => {
              const color = i % 2 === 0 ? "#F0F0F0" : "#fff";
              let color2;
              if (item.paymentStatus === "APPROVED") color2 = "#58b33d";

              if (item.paymentStatus === "REJECTED") color2 = "#da3737";

              if (item.paymentStatus === "PENDING") color2 = "#999898";

              return (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    margin: "1rem 0rem",
                    backgroundColor: color,
                    padding: "1rem 0",
                    borderTop: "none",
                    borderBottom: "solid 1px #999898",
                    ...(item.isPartial && {
                      ":hover": {
                        cursor: "pointer",
                      },
                    }),
                  }}
                  onClick={() => {
                    if (item.isPartial) {
                      setVisible(true);
                      setActualPayment(item);
                      setIsPartial(true);
                      setPayableAmount(item.numberOfQuotas === 4 ? 0 : 100);
                      setValues((prev) => ({
                        ...prev,
                        numberOfQuotas: item?.numberOfQuotas + 1,
                      }));
                    }
                  }}
                >
                  <Typography
                    sx={{
                      width: "16%",
                      flexShrink: 0,
                      textAlign: "center",
                      padding: "0 0.5rem",
                    }}
                  >
                    {item.paymentReference}
                  </Typography>
                  <Typography
                    sx={{
                      width: "16%",
                      flexShrink: 0,
                      textAlign: "center",
                      padding: "0 0.5rem",
                    }}
                  >
                    {item && item.title}
                  </Typography>
                  <Typography
                    sx={{
                      width: "16%",
                      flexShrink: 0,
                      textAlign: "center",
                      padding: "0 0.5rem",
                    }}
                  >
                    {formatDate(item.createdAt)}
                  </Typography>
                  <Typography
                    sx={{
                      width: "16%",
                      flexShrink: 0,
                      textAlign: "center",
                      padding: "0 0.5rem",
                    }}
                  >
                    {item.amount} VES
                  </Typography>
                  <Box
                    sx={{
                      width: "16%",
                      flexShrink: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "60%",
                        flexShrink: 0,
                        textAlign: "center",
                        padding: "0.16rem 0.5rem",
                        backgroundColor: color2,
                        borderRadius: "7px",
                        color: "#fff",
                        fontSize: "0.8rem",
                        boxShadow: "0px 0px 5px 0px   #999898",
                      }}
                    >
                      {item.paymentStatus === "APPROVED" && "APROBADO"}
                      {item.paymentStatus === "REJECTED" && "RECHAZADO"}
                      {item.paymentStatus === "PENDING" && "PENDIENTE"}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <Pagination
              count={totalPages - 1}
              shape="rounded"
              page={page}
              onChange={handleChange}
            />
          </Box>
          <Dialog
            visible={visible}
            onHide={() => {
              setVisible(false);
              setIsPartial(false);
              setPayableAmount(0);
            }}
            header="Pasarela de pago"
            draggable={false}
            resizable={false}
            style={{
              width: "80vw",
              height: "90vh",
            }}
          >
            <Box>
              {/* //* Titulo de la compra */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack textAlign={"center"} py={"1rem"} gap={"1rem"}>
                  <Typography
                    textAlign={"center"}
                    variant="h4"
                    sx={{ margin: "1rem 0rem" }}
                    style={{ textTransform: "capitalize" }}
                  >
                    {actualPayment && actualPayment.courseTitle}
                  </Typography>
                  <Typography variant="h6">
                    Pagar 500$ en las siguientes 4 cuotas: 200$, 100$, 100$,
                    100$
                  </Typography>
                  <Typography variant="h6">
                    Número de cuotas {values.numberOfQuotas}
                  </Typography>
                  <Typography variant="h6">
                    Cantidad a pagar:{" "}
                    {actualPayment?.isCompleted ? (
                      "Pago completado"
                    ) : (
                      <>
                        {payableAmount}$ / {(payableAmount * sicad).toFixed(2)}{" "}
                        BsS
                      </>
                    )}
                  </Typography>
                  {message && (
                    <Typography textAlign={"center"} variant="h6">
                      Su referencia de pago gratuito es: "111111111" Elige:
                      "Transferencia" Metodos de pago
                    </Typography>
                  )}
                </Stack>
              </Box>
              {/* //* Formulario */}
              {actualPayment?.isCompleted ? (
                <h1>El curso ha sido pagado exitosamente</h1>
              ) : (
                <Form onSubmit={handleSubmit}>
                  <Typography variant="h5">Metodos de pago</Typography>
                  {/* Contendor del grupo completo de inputs radio */}
                  <Box
                    sx={{
                      margin: "2rem",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="paymentMethod"
                        value={values.paymentMethod}
                        onChange={handleChangeValues}
                      >
                        <FormControlLabel
                          value="TRANSFERENCIA"
                          control={<Radio />}
                          label="Transferencia"
                        />
                        <FormControlLabel
                          value="PAGO_MOVIL"
                          control={<Radio />}
                          label="Pago Movil"
                        />
                        <FormControlLabel
                          value="EFECTIVO"
                          control={<Radio />}
                          label="Efectivo"
                        />
                      </RadioGroup>
                      {error && formatYupErrors(error.inner, "typePaid") && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {formatYupErrors(error.inner, "typePaid").message}
                        </span>
                      )}
                    </FormControl>
                    {isPartial && (
                      <>
                        <Typography variant="h5">Número de cuotas</Typography>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="numberOfQuotas"
                            value={values.numberOfQuotas}
                            onChange={(e) => {
                              handleChangeValues(e);
                              setPayableAmount(
                                calculatePayableAmount(
                                  Number(e.target.value),
                                  actualPayment
                                )
                              );
                            }}
                          >
                            {quotas.map((quota, i) => {
                              const isQuotaRemaining =
                                i + 1 > actualPayment.numberOfQuotas;

                              return isQuotaRemaining ? (
                                <FormControlLabel
                                  key={i}
                                  value={i + 1}
                                  control={<Radio />}
                                  label={`Cuota ${i + 1}: $${quota}`}
                                />
                              ) : (
                                <FormControlLabel
                                  key={i}
                                  value={i + 1}
                                  disabled
                                  control={<Radio />}
                                  label={`Cuota ${i + 1}: ${
                                    (actualPayment.partialPayments.filter(
                                      (prev) =>
                                        prev.paymentStatus !== "REJECTED"
                                    )[i].paymentStatus === "APPROVED" &&
                                      "Pagada") ||
                                    (actualPayment.partialPayments.filter(
                                      (prev) =>
                                        prev.paymentStatus !== "REJECTED"
                                    )[i].paymentStatus === "REJECTED" &&
                                      "Rechazada") ||
                                    "Pendiente"
                                  }`}
                                />
                              );
                            })}
                          </RadioGroup>
                          {/* Mostrar error si existe */}
                          {error &&
                            formatYupErrors(error.inner, "numberOfQuotas") && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {
                                  formatYupErrors(error.inner, "numberOfQuotas")
                                    .message
                                }
                              </span>
                            )}
                        </FormControl>
                      </>
                    )}
                  </Box>
                  {/* Plantilla que se va a mostrar segun el tipo de pago */}
                  {values.paymentMethod === "TRANSFERENCIA" && (
                    <Transfer amount={200} account={paymentAccount} />
                  )}
                  {values.paymentMethod === "PAGO_MOVIL" && (
                    <PaidMobile amount={200} account={paymentAccount} />
                  )}
                  {values.paymentMethod === "EFECTIVO" && (
                    <Cash amount={200} account={paymentAccount} />
                  )}
                  {values.paymentMethod === "" && null}

                  {/* Input para meter la Ref */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "1rem 0",
                    }}
                  >
                    <TextField
                      label="Ingrese el numero de referencia del pago"
                      name="paymentReference"
                      value={values.paymentReference}
                      onChange={handleChangeValues}
                      sx={{
                        "& > :not(style)": {
                          width: "80%",
                          marginTop: verifiedCoupon ? 3 : 0,
                        },
                      }}
                      fullWidth
                    />
                    {!loading && (
                      <>
                        {" "}
                        {verifiedCoupon && (
                          <Stack direction="column">
                            {" "}
                            <h4>
                              Cupón aprobado!
                              <VerifiedIcon />
                            </h4>
                          </Stack>
                        )}
                        {!verifiedCoupon && (
                          <Box
                            component="form"
                            sx={{
                              "& > :not(style)": { width: "25ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              onChange={(event) =>
                                setCoupon(event.target.value)
                              }
                              onid="outlined-basic"
                              label="Introducir cupón"
                              variant="outlined"
                            />
                            <IconButton
                              variant="contained"
                              size="small"
                              onClick={handleCouponVerification}
                            >
                              <AddIcon />
                              Verificar
                            </IconButton>
                          </Box>
                        )}
                      </>
                    )}
                    {loading && (
                      <>
                        {" "}
                        <ClipLoader color="blue" size={30} /> verificando{" "}
                      </>
                    )}
                  </Box>
                  {error &&
                    formatYupErrors(error.inner, "paymentReference") && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {
                          formatYupErrors(error.inner, "paymentReference")
                            .message
                        }
                      </span>
                    )}
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        margin: "0 5px ",
                      }}
                    >
                      Nota:
                    </Typography>
                    <Typography>
                      El pago sera aprobado luego de ser verificado. Esto puedo
                      tardar 72h
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "2rem ",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={
                        !values.paymentMethod || !values.paymentReference
                      }
                    >
                      Comprar
                    </Button>
                  </Box>
                </Form>
              )}
            </Box>
          </Dialog>
        </Container>
      )}
    </>
  );
};

export default MyPayments;
