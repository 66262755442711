import React, { useEffect, useState } from "react";
import { MainButton } from "components/common/Buttons/MainButton/styles";
import { Dialog } from "primereact/dialog";
import { Title } from "pages/Admin/Courses/CreateCategory/styles";
import { ButtonContainer } from "pages/Admin/Courses/style";
import { Form, Formik } from "formik";
import Input from "components/common/Forms/Inputs";
import Selects2 from "components/common/Forms/Selects2";
import { Box, Button } from "@mui/material";
import { RemoveRedEye } from "@material-ui/icons";
import { Formulario, Modal } from "./styles";
import {
  BoxButton,
  LeftSide,
  RightSide,
} from "pages/Profile/EditProfile/styles";
import { BASE_URL, endPoints } from "const/endPoints";
import { formatDate } from "utils/formatDate";
import { fetchWithToken } from "helpers/fetch";

const ViewUserForm = ({ userForm = {} }) => {
  // State para manejo del modal y la página actual
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const click = () => setVisible(true);
  const close = () => setVisible(false);

  const onHide = () => setVisible(false);

  // Paginación
  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);

  const INITIAL_VALUES = {
    fullName: userForm.fullName,
    lastNames: userForm.lastNames,
    placeOfBirth: userForm.placeOfBirth,
    birthDate: userForm.birthDate,
    ci: userForm.ci,
    mpps: userForm.mpps,
    nrcm: userForm.nrcm,
    phone: userForm.phone,
    email: userForm.email,
    address: userForm.address,
    placeOfWork: userForm.placeOfWork,
    status: userForm.status,
    user: userForm.user,
    createdAt: userForm.createdAt,
    images: userForm.images,
  };

  useEffect(() => {
    const updateStatus = async (status) => {
      const resp = await fetchWithToken(
        endPoints.update_user_form + "/" + userForm.id,
        { status },
        "PUT"
      );

      const data = await resp.json();
      console.log(data);
    };

    if (status !== null) {
      updateStatus(status);
    }
  }, [status, userForm.id]);

  return (
    <>
      <Button size="small" onClick={() => click()}>
        <RemoveRedEye color="success" />
      </Button>

      <Modal>
        <Dialog
          header="Visualizar formulario de usuario"
          visible={visible}
          style={{
            width: "60vw",
            margin: "50px 0px 0 0",
          }}
          position="center"
          contentStyle={{
            borderRadius: "15px",
          }}
          draggable={false}
          showHeader={false}
          resizable={false}
          className="dialog"
          onHide={() => onHide()}
        >
          <ButtonContainer>
            <MainButton
              backgroundColor="rgb(255, 255, 255)"
              backgroundColorHover="rgb(7, 7, 7, 0.1)"
              height="32px"
              width="32px"
              padding="5px 0 0 0"
              margin="1rem 0rem 0rem 0rem"
              color="#292929"
              onClick={() => close()}
            >
              X
            </MainButton>
          </ButtonContainer>
          <Title>Visualizar formulario de usuario</Title>
          <Formik initialValues={INITIAL_VALUES}>
            <Form>
              {currentPage === 1 && (
                <Formulario>
                  <LeftSide
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="fullName"
                      style={{
                        margin: "10px 0px",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      Nombre completo
                    </label>
                    <Input
                      id="fullName"
                      name="fullName"
                      type="text"
                      placeholder="Nombre completo"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />

                    <label htmlFor="lastNames" style={{ margin: "10px 0px" }}>
                      Apellidos
                    </label>
                    <Input
                      id="lastNames"
                      name="lastNames"
                      type="text"
                      placeholder="Apellidos"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />

                    <label
                      htmlFor="placeOfBirth"
                      style={{ margin: "10px 0px" }}
                    >
                      Lugar de nacimiento
                    </label>
                    <Input
                      id="placeOfBirth"
                      name="placeOfBirth"
                      type="text"
                      placeholder="Lugar de nacimiento"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />

                    <label htmlFor="birthDate" style={{ margin: "10px 0px" }}>
                      Fecha de nacimiento
                    </label>
                    {formatDate(userForm.birthDate)}
                  </LeftSide>

                  <RightSide>
                    <label htmlFor="ci" style={{ margin: "10px 0px" }}>
                      Cédula de identidad
                    </label>
                    <Input
                      id="ci"
                      name="ci"
                      type="text"
                      placeholder="Cédula de identidad"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />

                    <label htmlFor="mpps" style={{ margin: "10px 0px" }}>
                      MPPS
                    </label>
                    <Input
                      id="mpps"
                      name="mpps"
                      type="text"
                      placeholder="MPPS"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />

                    <label htmlFor="nrcm" style={{ margin: "10px 0px" }}>
                      NRCM
                    </label>
                    <Input
                      id="nrcm"
                      name="nrcm"
                      type="text"
                      placeholder="NRCM"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />

                    <label htmlFor="phone" style={{ margin: "10px 0px" }}>
                      Teléfono
                    </label>
                    <Input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Teléfono"
                      width={"75%"}
                      heigth={"50px"}
                      margin={"10px 0px"}
                      errorPadding="0 0 0 15%"
                      backgroundColor={"#fff"}
                      disabled
                    />
                  </RightSide>
                </Formulario>
              )}

              {currentPage === 2 && (
                <Formulario>
                  <label
                    htmlFor="email"
                    style={{
                      margin: "10px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Correo electrónico
                  </label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Correo electrónico"
                    width={"75%"}
                    heigth={"50px"}
                    margin={"10px 0px"}
                    errorPadding="0 0 0 15%"
                    backgroundColor={"#fff"}
                    disabled
                  />

                  <label
                    htmlFor="address"
                    style={{
                      margin: "10px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Dirección
                  </label>
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    placeholder="Dirección"
                    width={"75%"}
                    heigth={"50px"}
                    margin={"10px 0px"}
                    errorPadding="0 0 0 15%"
                    backgroundColor={"#fff"}
                    disabled
                  />

                  <label
                    htmlFor="placeOfWork"
                    style={{
                      margin: "10px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Lugar de trabajo
                  </label>
                  <Input
                    id="placeOfWork"
                    name="placeOfWork"
                    type="text"
                    placeholder="Lugar de trabajo"
                    width={"75%"}
                    heigth={"50px"}
                    margin={"10px 0px"}
                    errorPadding="0 0 0 15%"
                    backgroundColor={"#fff"}
                    disabled
                  />

                  <Selects2
                    id="status"
                    name="status"
                    options={[{ name: "Activo" }, { name: "Inactivo" }]}
                    previousValue={userForm.status}
                    text="Estado"
                  />
                </Formulario>
              )}

              {currentPage === 3 && (
                <Formulario>
                  <RightSide>
                    <Box flex flexDirection="column" alignItems="center">
                      {Object.entries(userForm.images).map(([key, value]) => {
                        // Verifica si el archivo es un PDF o una imagen
                        const isPdf = value.endsWith(".pdf");
                        const isImage = value.match(/\.(jpg|jpeg|png|gif)$/i);

                        return (
                          <Box
                            key={key}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            margin="10px"
                          >
                            <span>{key}</span>

                            {isPdf ? (
                              // Si es un PDF, mostrarlo como un link
                              <a
                                href={
                                  BASE_URL + "/users/form/getAllImages/" + value
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Ver archivo PDF
                              </a>
                            ) : isImage ? (
                              // Si es una imagen, mostrarla como una imagen
                              <a
                                href={
                                  BASE_URL + "/users/form/getAllImages/" + value
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    BASE_URL +
                                    "/users/form/getAllImages/" +
                                    value
                                  }
                                  alt={key}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </a>
                            ) : (
                              // Si no es ni imagen ni PDF, no mostrar nada (o puedes agregar un caso por defecto)
                              <span>Archivo no compatible</span>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </RightSide>
                </Formulario>
              )}

              <BoxButton>
                {currentPage > 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={prevPage}
                    style={{ marginRight: "10px" }}
                  >
                    Anterior
                  </Button>
                )}
                {currentPage > 2 && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => setStatus(true)}
                      style={{ marginRight: "10px" }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => setStatus(false)}
                      style={{ marginRight: "10px" }}
                    >
                      Rechazar
                    </Button>
                  </>
                )}
                {currentPage < 3 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={nextPage}
                  >
                    Siguiente
                  </Button>
                )}
              </BoxButton>
            </Form>
          </Formik>
        </Dialog>
      </Modal>
    </>
  );
};

export default ViewUserForm;
