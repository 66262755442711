import { Box, Container, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { fetchWithToken } from "helpers/fetch";
import { endPoints } from "const/endPoints";

import Spinner from "components/common/Spinner";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";

const Users = () => {
  //*Guardamos la lista de usuarios de la base de datos
  const [users, setUsers] = useState([]);
  const [flag, setFlag] = useState(false);
  //*fetch para obtener usuarios
  useEffect(() => {
    const fetchUsers = async () => {
      const resp = await fetchWithToken(endPoints.get_all_users);
      const data = await resp.json();
      setUsers(data);
    };
    fetchUsers();
    //para que se repita cada vez que borre un usuario
    setFlag(false);
  }, [flag]);

  //*Paginacion
  const [page, setPage] = useState(1); //pagina actual
  const n = 6; //Numero de elementos por paginas
  const totalPages = Math.ceil(users.length / n) + 1; //total de paginas

  const handleChange = (event, value) => {
    setPage(value);
  };
  //*Funcion para listar los usuarios por pagina
  const setUsersInPage = () => {
    let pagination = {};
    let j = 0;
    for (let i = 1; i <= totalPages; i++) {
      pagination[i] = users.slice(j * n, j * n + n);
      j++;
    }

    return pagination;
  };
  const itemList = setUsersInPage();
  if (users.length === 0) {
    return <Spinner />;
  }
  return (
    <Container
      sx={{
        padding: "2rem 0",
        height: "100vh",
      }}
    >
      {/* //* TITULO */}
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 0 2rem 0",
          fontSize: "1.5rem",
        }}
      >
        USUARIOS
      </Typography>

      {/* //* header del historial de compras */}
      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "flex-start", // Alinea las columnas al inicio
          alignItems: "center",
          padding: "1.5rem 20px",
          boxShadow: "0px 0px 5px -1px   #999898",
          gap: "1rem", // Espacio entre columnas
        }}
      >
        <Typography
          sx={{
            flex: 1,
            minWidth: "50px",
            textAlign: "start", // Texto alineado al inicio
            fontWeight: "600",
          }}
        >
          ID
        </Typography>
        <Typography
          sx={{
            flex: 2,
            minWidth: "100px",
            textAlign: "start",
            fontWeight: "600",
          }}
        >
          Nombre
        </Typography>
        <Typography
          sx={{
            flex: 2,
            minWidth: "100px",
            textAlign: "start",
            fontWeight: "600",
          }}
        >
          Usuario
        </Typography>
        <Typography
          sx={{
            flex: 3,
            minWidth: "150px",
            textAlign: "start",
            fontWeight: "600",
          }}
        >
          Correo
        </Typography>
        <Typography
          sx={{
            flex: 2,
            minWidth: "100px",
            textAlign: "start",
            fontWeight: "600",
          }}
        >
          Rol
        </Typography>
        <Typography
          sx={{
            flex: 2,
            minWidth: "100px",
            textAlign: "start",
            fontWeight: "600",
          }}
        >
          Estado
        </Typography>
        <Typography
          sx={{
            flex: 2,
            minWidth: "100px",
            textAlign: "start",
            fontWeight: "600",
          }}
        >
          Acciones
        </Typography>
      </Box>

      {/* //* Contenido del historial de compras */}
      <Box
        sx={{
          margin: "1.5rem 0rem",
          height: "70%",
        }}
      >
        {itemList[page].map((item, i) => {
          const color = i % 2 === 0 ? "#F0F0F0" : "#fff";
          return (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                justifyContent: "flex-start", // Alinea las columnas al inicio
                alignItems: "center",
                margin: "1rem 0rem",
                backgroundColor: color,
                padding: "1rem 20px",
                borderBottom: "solid 1px #999898",
                gap: "1rem", // Espacio entre columnas
              }}
            >
              <Typography
                sx={{
                  flex: 1,
                  minWidth: "50px",
                  textAlign: "start", // Texto alineado al inicio
                }}
              >
                {item.id.substring(item.id.length - 4, item.id.length)}
              </Typography>
              <Typography
                sx={{
                  flex: 2,
                  minWidth: "100px",
                  textAlign: "start",
                }}
              >
                {item.firstName} {item.lastName}
              </Typography>
              <Typography
                sx={{
                  flex: 2,
                  minWidth: "100px",
                  textAlign: "start",
                }}
              >
                {item.username}
              </Typography>
              <Typography
                sx={{
                  flex: 3,
                  minWidth: "150px",
                  textAlign: "start",
                }}
              >
                {item.email}
              </Typography>
              <Typography
                sx={{
                  flex: 2,
                  minWidth: "100px",
                  textAlign: "start",
                  backgroundColor: "#999898",
                  borderRadius: "7px",
                  color: "#fff",
                  fontSize: "0.8rem",
                  padding: "0.16rem 0.5rem",
                }}
              >
                {item.role}
              </Typography>
              <Typography
                sx={{
                  flex: 2,
                  minWidth: "100px",
                  textAlign: "start",
                  backgroundColor: "#999898",
                  borderRadius: "7px",
                  color: "#fff",
                  fontSize: "0.8rem",
                  padding: "0.16rem 0.5rem",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  await fetchWithToken(
                    endPoints.update_any_user + `/${item.id}`,
                    {
                      status: !item.status,
                    },
                    "PUT"
                  );
                  setFlag(true);
                }}
              >
                {item.status ? "Activo" : "Inactivo"}
              </Typography>
              <Box
                sx={{
                  flex: 2,
                  minWidth: "100px",
                  textAlign: "start",
                  display: "flex",
                  gap: "0.5rem",
                }}
              >
                <EditUser user={item} flag={setFlag} />
                <DeleteUser id={item.id} flag={setFlag} />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.5rem",
        }}
      >
        <Pagination
          count={totalPages - 1}
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
};

export default Users;
